<template>
    <v-dialog v-model="visible" persistent max-width="850px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">
                    {{ form.id ? 'Edição' : 'Cadastro' }} de Cliente
                </span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                :style="`height: ${$vuetify.breakpoint.height - 250}px; overflow-y: auto;`"
            >
                <v-form ref="form" class="mt-2">
                    <v-divider/>
                    <v-card-title>Dados de Contato</v-card-title>
                    <v-row v-if="form.id">
                        <v-col cols="6">
                            <v-text-field
                                v-model="form.id"
                                label="Código"
                                disabled
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="form.pontos"
                                label="Pontos"
                                readonly
                                filled
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                ref="nome"
                                label="Nome *"
                                v-model="form.nome"
                                :rules="[rules.empty]"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Whatsapp"
                                v-model="form.whatsapp"
                                :rules="[rules.whatsapp]"
                                prefix="+55"
                                placeholder="(99) 99999-9999"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                @keypress.enter="consultaCnpj"
                                label="CPF / CNPJ"
                                v-model="form.cpf_cnpj"
                                :rules="[rules.cpfCnpj]"
                                :loading="loadingCnpj"
                                v-mask="['###.###.###-##', '##.###.###/####-##']"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Inscrição Estadual"
                                v-model="form.inscricao_estadual"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="E-Mail"
                                v-model="form.email"
                                :rules="[rules.email]"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="form.nascimento"
                                label="Data de Nascimento"
                                type="date"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="form.observacao"
                                label="Observação"
                                clear-icon="mdi-backspace-outline"
                                hide-details
                                clearable
                                outlined
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="form.black_list"
                                label="Bloquear envio de campanhas"
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-divider class="my-4"/>
                    <v-card-title>Dados de Endereço</v-card-title>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                @keypress.enter="consultaCep"
                                v-model="form.enderecos[0].cep"
                                :loading="loadingCep"
                                label="CEP (Enter para pesquisar)"
                                v-mask="['#####-###']"
                                hide-details
                                outlined
                                dense
                                @change="onChangeAddress"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                :items="ufs"
                                v-model="form.enderecos[0].uf"
                                outlined
                                dense
                                label="UF"
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col v-if="municipios.length">
                            <v-autocomplete
                                v-model="form.enderecos[0].municipio"
                                :items="municipios"
                                @change="onChangeAddress"
                                label="Município"
                                item-value="descricao"
                                item-text="descricao"
                                outlined
                                dense
                                clearable
                                hide-details
                                hide-selected
                            >
                                <template #append-item>
                                    <div
                                        style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                        @click="newMunicipio"
                                    >
                                        <v-icon>mdi-plus</v-icon> Cadastrar novo município
                                    </div>
                                </template>
                                <template #append>
                                    <v-btn
                                        @click="newMunicipio"
                                        small
                                        outlined
                                    >
                                        <v-icon>mdi-plus</v-icon> Novo
                                    </v-btn>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-if="isFretePorBairro"
                                v-model="form.enderecos[0].bairro"
                                :items="bairros"
                                @change="onChangeAddress"
                                label="Bairro"
                                item-value="descricao"
                                item-text="descricao"
                                outlined
                                dense
                                clearable
                                hide-details
                                hide-selected
                            >
                                <template #append-item>
                                    <div
                                        style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                        @click="newBairro"
                                    >
                                        <v-icon>mdi-plus</v-icon> Cadastrar novo bairro
                                    </div>
                                </template>
                                <template #append>
                                    <v-btn
                                        @click="newBairro"
                                        small
                                        outlined
                                    >
                                        <v-icon>mdi-plus</v-icon> Novo
                                    </v-btn>
                                </template>
                            </v-autocomplete>
                            <v-text-field
                                v-else
                                v-model="form.enderecos[0].bairro"
                                label="Bairro"
                                hide-details
                                outlined
                                dense
                                @change="onChangeAddress"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                label="Logradouro"
                                v-model="form.enderecos[0].rua"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Número"
                                v-model="form.enderecos[0].numero"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Complemento, Ponto de Referência, Cor da Casa..."
                                v-model="form.enderecos[0].referencia"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-radio-group
                                v-model="form.enderecos[0].tipo_local"
                                row
                                hide-details
                                style="margin-top: 5px;"
                            >
                                <v-radio
                                    label="Casa"
                                    value="casa"
                                />
                                <v-radio
                                    label="Apartamento"
                                    value="apartamento"
                                />
                                <v-radio
                                    label="Trabalho"
                                    value="trabalho"
                                />
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="form.enderecos[0].tipo_local === 'apartamento'">
                        <v-col cols="6">
                            <v-text-field
                                hide-details
                                label="Nome do prédio"
                                v-model="form.enderecos[0].nome_edificio"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                hide-details
                                label="Nº do apartamento"
                                v-model="form.enderecos[0].numero_apartamento"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="form.enderecos[0].observacao"
                                label="Observação"
                                clear-icon="mdi-backspace-outline"
                                hide-details
                                clearable
                                outlined
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Taxa de Entrega"
                                v-model="form.enderecos[0].valor_frete"
                                v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                prefix="R$"
                                hide-details
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                v-if="distancias.length"
                                @click="calcularFrete"
                                small
                                color="primary"
                                :loading="loadingCalcFrete"
                            >
                                <v-icon small>mdi-google-maps</v-icon>
                                Calcular Frete por Raio de Distância
                            </v-btn>
                        </v-col>
                    </v-row>

                    <div v-if="id">
                        <v-divider class="my-4"/>
                        <v-card-title>Histórico de Pedidos</v-card-title>

                        <HistoricoPedidos
                            :clienteId="id"
                        />
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
        <CadastroMunicipio
            :visible="municipioVisible"
            :formData="formMunicipio"
            @onCadastro="onCadastroMunicipio"
            @close="municipioVisible = false"
        />
        <CadastroBairro
            :visible="bairroVisible"
            :formData="formBairro"
            @onCadastro="onCadastroBairro"
            @close="bairroVisible = false"
        />
    </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { clone } from '@/utils/utils';
import ufs from '@/utils/ufs';
import gmapsInit from '@/plugins/gmaps';
import distance from '@/utils/distance';
import HistoricoPedidos from './HistoricoPedidos.vue';
import CadastroMunicipio from '@/pages/cadastro/Municipio.vue';
import CadastroBairro from '@/pages/cadastro/Bairro.vue';

export default {
    name: 'Cliente',

    components: {
        HistoricoPedidos,
        CadastroMunicipio,
        CadastroBairro,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        id: {
            type: [ Number, String ],
            default: null
        },

        name: {
            type: [String],
            default: null,
        },
    },

    data: () => ({
        loading: false,
        loadingCalcFrete: false,
        loadingCep: false,
        loadingCnpj: false,
        initialForm: {
            status: 'ativo',
            nome: null,
            cpf_cnpj: null,
            inscricao_estadual: null,
            whatsapp: null,
            email: null,
            nascimento: null,
            observacao: null,
            black_list: false,
            enderecos: [
                {
                    id: null,
                    observacao: null,
                    municipio: null,
                    bairro: null,
                    rua: null,
                    numero: null,
                    referencia: null,
                    valor_frete: null,
                },
            ],
        },
        form: {},
        ufs: [],
        googleMaps: null,
        rules: {
            email: value => {
                if (!value) {
                    return true;
                }
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido';
            },

            cpfCnpj: value => {
                if (!value) {
                    return true;
                }
                const cpfCnpjPattern = /^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/
                return cpfCnpjPattern.test(value) || 'CPF ou CNPJ inválido';
            },

            empty: value => !!value || 'Preenchimento obrigatório',

            whatsapp: value => !value || (value || '').toString().trim().length > 9 || 'Preenchimento obrigatório',

            fullName: value => (value || '').trim().split(' ').length > 1 || 'Informe o nome completo',
        },
        municipioVisible: false,
        formMunicipio: {},
        bairroVisible: false,
        formBairro: {},
    }),

    created() {
        this.init();
        this.reset();
    },

    computed: {
        ...mapState([
            'bairros',
            'municipios',
            'distancias',
            'configuracoes',
        ]),

        getEnderecoCompleto() {
            let text = '';

            const municipio = this.form.enderecos[0].municipio || this.configuracoes.municipio;

            text += `${municipio}, `;
            text += `${this.configuracoes.uf}, `;
            text += this.form.enderecos[0].bairro ? `${this.form.enderecos[0].bairro}, ` : ''
            text += this.form.enderecos[0].rua ? `${this.form.enderecos[0].rua}, ` : ''
            text += this.form.enderecos[0].numero ? `${this.form.enderecos[0].numero}` : ''

            return text;
        },

        isFretePorBairro() {
            return this.configuracoes?.frete_tipo === 'Bairro';
        },
    },

    watch: {
        visible(v) {
            v && this.consultar();
        },
    },

    methods: {
        ...mapMutations({
            setMunicipios: 'setMunicipios',
            setBairros: 'setBairros',
        }),

        async init() {
            this.ufs = ufs;
            try {
                this.googleMaps = await gmapsInit();
            } catch (e) {
                console.log(e);
            }
        },

        consultar() {
            this.reset();

            if (!this.id) {
                return;
            }

            this.$root.$emit('loading', true);
            this.$http.get(`clientes/${this.id}`).then(({ data }) => {
                !data.enderecos.length && data.enderecos.push(clone(this.initialForm.enderecos[0]));

                if (data.enderecos[0].bairro) {
                    const index = this.bairros.findIndex(b => b.descricao == data.enderecos[0].bairro);
                    index === -1 && this.bairros.push({ descricao:  data.enderecos[0].bairro});
                }

                this.form = data;
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => this.$root.$emit('loading', false));
        },

        salvar() {
            if (!this.$refs.form.validate()) {
                this.notify('Preencha os campos obrigatórios', 'warning');
                return;
            }

            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`clientes/${id}`, this.form).then(resp => {
                    const data = resp.data;
                    if (data.type == 'warning') {
                        this.notify(data.msg, 'warning');
                        return;
                    }
                    this.$emit('save', data.data);
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('clientes', this.form).then(resp => {
                const data = resp.data;
                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }
                this.$emit('save', data.data);
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        reset() {
            this.form = clone(this.initialForm);

            if (this.name) {
                this.form.nome = this.name;
            }

            this.$nextTick(() => this.$refs.nome?.focus());
        },

        async calcularFrete() {
            if (!this.distancias.length) {
                this.notify('Nenhum raio de entrga cadastrado', 'warning');
                return;
            }

            this.loadingCalcFrete = true;
            const geocoder = new this.googleMaps.maps.Geocoder();

            const address = this.getEnderecoCompleto;

            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK') {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();

                    this.validarRaioEntrega({ lat, lng });
                 } else if (status === 'ZERO_RESULTS') {
                    this.notify('Endereço não encontrado');
                } else {
                    this.notify('Falha na geocodificação');
                    console.log(`Falha na geocodificação. Status: ${status}`);
                }
                this.loadingCalcFrete = false;
            });
        },

        validarRaioEntrega(pos) {
            const origem = this.configuracoes.geolocalizacao;

            const destino = {
                latitude: pos.lat,
                longitude: pos.lng,
            };

            const km = distance.between(origem, destino);
            const limiteEntrega = Math.max.apply(Math, this.distancias.map(o => o.fim));

            if (km > limiteEntrega) {
                this.notify(`Fora do raio de entrega, a distância é de ${km} Km`, 'warning');
                return;
            }

            this.distancias.forEach(f => {
                if (km >= +f.inicio && km <= +f.fim) {
                    this.form.enderecos[0].valor_frete = +f.valor;
                    this.notify('Taxa de entrega R$' + this.form.enderecos[0].valor_frete.toFixed(2));
                }
            });
        },

        onChangeAddress() {
            if (this.configuracoes.frete_tipo === 'Município' && this.form.enderecos[0].municipio) {
                const find = this.municipios.find(e => e.descricao === this.form.enderecos[0].municipio);

                if (!find) {
                    return;
                }

                this.form.valor_frete = find.valor;
                // this.notify('Taxa de entrega R$' + this.formatPrice(find.valor));
            }

            if (this.configuracoes.frete_tipo === 'Bairro' && this.form.enderecos[0].bairro) {
                const find = this.bairros.find(e => e.descricao === this.form.enderecos[0].bairro);

                if (!find) {
                    return;
                }

                this.form.enderecos[0].valor_frete = (+find.valor).toFixed(2);
                // this.notify('Taxa de entrega R$' + this.formatPrice(find.valor));
            }
        },

        async consultaCep() {
            if (!this.form.enderecos[0].cep || this.form.enderecos[0].cep == '') {
                return;
            }

            const cep = this.form.enderecos[0].cep.replace(/\D/g, '');
            let validacep = /^[0-9]{8}$/;

            if (!validacep.test(cep)) {
                this.notify('Informe um cep válido', 'warning');
                return;
            }

            this.loadingCep = true;
            const request = await fetch(`https://viacep.com.br/ws/${cep}/json`, { method: 'GET' });
            let resp = await request.json();

            // await this.saveMunicipio(resp.localidade);

            this.form.enderecos[0].uf = resp.uf;
            this.form.enderecos[0].bairro = resp.bairro;
            this.form.enderecos[0].municipio = resp.localidade;
            this.form.enderecos[0].rua = resp.logradouro;

            this.loadingCep = false;
        },

        async consultaCnpj() {
            if (this.form.cpf_cnpj.length <= 14) {
                return;
            }

            const cnpj = this.form.cpf_cnpj.replace(/\D/g, '');
            let validaCnpj = /^[0-9]{14}$/;

            if (!validaCnpj.test(cnpj)) {
                this.notify('Informe um cnpj válido', 'warning');
                return;
            }

            this.loadingCnpj = true;
            const request = await fetch(`https://minhareceita.org/${cnpj}`, { method: 'GET' });
            let resp = await request.json();

            console.log(resp);
            this.loadingCnpj = false;
        },

        // async saveMunicipio(descricao) {
        //     const data = {
        //         status: 'inativo',
        //         descricao,
        //         valor: 0,
        //     };
        //     await this.$http.post('municipios', data).then(async resp => {
        //         const data = resp.data;
        //         if (data.type == 'warning') {
        //             this.notify(data.msg, 'warning');
        //             return;
        //         }

        //         this.notify('Município cadastrado com sucesso');

        //         await this.retrieveMunicipios();
        //     }).catch(() => {
        //         this.notify('Verifique sua conexão com a internet', 'warning');
        //     });
        // },

        async retrieveMunicipios() {
            await this.$http.get('municipios').then(resp => {
                const data = resp.data?.data;

                this.setMunicipios(data);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        newMunicipio() {
            this.formMunicipio = { ativo: false, status: 'inativo' };
            this.municipioVisible = true;
        },

        onCadastroMunicipio() {
            this.municipioVisible = false;
            this.retrieveMunicipios();
        },

        async retrieveBairros() {
            await this.$http.get('bairros').then(resp => {
                const data = resp.data?.data;

                this.setBairros(data);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        newBairro() {
            this.formBairro = { ativo: false };
            this.bairroVisible = true;
        },

        onCadastroBairro() {
            this.bairroVisible = false;
            this.retrieveBairros();
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-card__title {
    padding-left: 0;
}
</style>
